import React, { useState, createContext } from "react"

export const SearchContext = createContext()

export const SearchProvider = ({ children }) => {
  const [searchInput, setSearchInput] = useState("")
  const [searchTime, setSearchTime] = useState("")
  const [suggestions, setSuggestions] = useState("")

  return (
    <SearchContext.Provider
      value={{
        searchInput,
        setSearchInput,
        searchTime,
        setSearchTime,
        suggestions,
        setSuggestions
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}
